import React from 'react'
import HomeScene from '../components/fiber/HomeScene'
import SEO from "../components/seo"
import {useTranslations, useGlobalContext} from '../layouts/globals'




export default () => {
    const {lang, translations: {title, description, hero}} = useTranslations()
    const [{mode}] = useGlobalContext()

    
    const _title = hero.hero_title.split('%s').map(r => r.trim())
    const cats = () => [
          { path: `${lang === 'en' ? '/en':''}/web`, 
           header: hero.portfolio_title, 
           description: hero.portfolio_desctiption},
          { path: `${lang === 'en' ? '/en':''}/integration`,
           header: hero.datamanagement_title, 
           description: hero.datamanagement_desctiption}
        ]
      
    
    return <>
        <SEO {...{lang, title, description}}/>

        <HomeScene cats={cats} title={_title} mode={mode} />
    </>
}