import React, {useState, useEffect, useMemo, useRef, Suspense , useContext} from 'react'
import { Link } from 'gatsby'
import create from 'zustand'
import { Canvas,  useThree } from 'react-three-fiber'
import { useSpring, useTransition, a } from 'react-spring/three'
import useSVGLoader from './SVGLoader'
import * as THREE from 'three'
import {HTML} from 'drei'
import {useTranslations, useGlobalContext} from '../../layouts/globals' 
import styled, {withTheme} from 'styled-components'
import css from '@styled-system/css'
import {Copywrite} from '../../components/footer'

const [useStore] = create(set => ({
    scene_index: 0,
    updateSceneIndex: (scene_index) => set({scene_index})
}))

const colors = [  '#8bd8d2', '#f7b3c4']
const dark_colors = ['#403726', '#444']

const deg = THREE.MathUtils.degToRad

const SceneWrapper = ({mode}) => {
    const svgs: any = useSVGLoader()
    const shapes = useMemo(
        () => {
          return svgs.map(({ paths }) =>
          paths.flatMap((path, index) =>
            path
              .toShapes(true)
              .map((shape) => ({ shape, color: path.color, fillOpacity: 1, index }))
          )
        )
        },
        [svgs]
      )
    
    return <Scene {...{shapes, mode}} />
}

const Scene = React.memo(({ shapes, mode }: any) => {
  
    

    const bg_colors = mode === 'dark' ? dark_colors : colors

    const scene_index = useStore(state => state.scene_index)
    const { color } = useSpring({
        from: { color: bg_colors[0] },
        color: bg_colors[scene_index],
        delay: 500,
        config: { mass: 5, tension: 800, friction: 400 },
      })
  
      const transitions = useTransition(shapes[scene_index], (item) => item.shape.uuid, {
        from: { rotation: [-0.2, 0.9, 0], position: [0, 50, -200], opacity: 0 },
        enter: { rotation: [0, 0, 0], position: [0, 0, 0], opacity: 1 },
        leave: { rotation: [0.2, -0.9, 0], position: [0, -400, 200], opacity: 0 },
        config: { mass: 30, tension: 800, friction: 190, precision: 0.0001 },
        ...{ order: ['leave', 'enter', 'update'], trail: 5, lazy: true, unique: true, reset: true }
      })
      
      
      return (
        <>        
          <mesh scale={[20000, 20000, 1]} rotation={[0, 0, 0]}>
            <planeBufferGeometry attach="geometry" args={[1, 1]} />
            <a.meshPhongMaterial attach="material" color={color} depthTest={false} />
          </mesh>
          <group position={[100, 0, 0]} rotation={[0, deg(170), Math.PI]}>
            {transitions.map(
              ({ item: { shape, color, fillOpacity, index }, key, props: { opacity, position, rotation } }) => (
                  <Shape {...{shape, color, fillOpacity, index, key, opacity, position, rotation }}/>
              )
            )}
          </group>
        </>
      )
    })


const Shape = ({shape, rotation, key, position, color, opacity, fillOpacity, index}) =>  
    <a.mesh key={key} rotation={rotation} position={position.interpolate((x, y, z) => [x-1200, y-1200, z + -30 * index])}>
        <a.meshPhongMaterial
        attach="material"
        color={color}
        opacity={opacity.interpolate((o) => o * fillOpacity)}
        depthWrite={false}
        side={THREE.DoubleSide}
        transparent
        />
        <shapeBufferGeometry attach="geometry" args={[shape]} />
    </a.mesh>



function HtmlContent({ className, style, children, portal }) {
  const { size } = useThree()
  return (
    <HTML
      portal={portal}
      style={{ position: 'absolute',
              top: -size.height / 2,
              left: -size.width / 2,
              width: '100vw',
              height: '100vh'}}
      >
        {children}      
    </HTML>
  )
}

const ProgressText = ({time = 5000, text, index, hidden}) => {
  const ref: any = useRef()
  useEffect(() => {
    ref.current.style.animation = 'none'
    
    void ref.current.offsetHeight
    ref.current.style.animation = `changewidth ${time / 1000}s linear`
  }, [index])

  return <span hidden={hidden} className="transition vertical">
                {text}
                <span className="progress" ref={ref}/>
              </span>
}

export function Categories({ time = 5000, ...props}) {
  
    const {cats, title, theme } = props

    const updateSceneIndex = useStore(state => state.updateSceneIndex)
    const [index, set] = useState(0)
    useEffect(() => {
      const interval = setInterval(() => set((index + 1) % 2), time)
      updateSceneIndex(index)
      return () => clearInterval(interval)
    }, [index])
  
    
    const texts = cats()
    return (
      <HeroTextWrapper theme={theme}>
        <h2> <span className="title">{title[0]}</span>
          <Link to={texts[index].path} >
            
            
            {texts.map(({ header }, i) => <ProgressText key={i} text={header} index={index} hidden={i !== index || undefined} />)}
          </Link>
            <span className="title">{title[1]}</span>
        </h2>

        {texts.map(({ description }, i) => (
          <span key={i} hidden={i !== index || undefined} className="transition horizontal" >
            {description}
          </span>
        ))}
      </HeroTextWrapper>
    )
  }

const HeroTextWrapper = styled.div`

position: relative;
top: 150px;
height: 80vh;
h2{
  position: relative;
  left: -20px;
  padding: 20px;

}
h2 a {
  position: relative;
  display: block;
  span {
    
  }
  
}
span.transition {
  display:block;
}

.progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  opacity: 0.5;
  background: ${({theme}) => 
    theme.colors.active
  };
}
${css({
  p: [3,4],
  'h2': {
    fontSize: ['34px', '64px'], 
    lineHeight: ['3rem', '4rem'],
    letterSpacing: [, '-2px'],
    borderRadius: '4px',
    display: 'box',
    
    'span.title': {
      bg: 'border',
      p: 1,

    },

    'a': {height: ['3rem', '4rem']},

    'a:hover .transition.vertical': {
      bg: 'active',
      color: 'invert_active'
      
    }
  },
  
  '.transition.vertical': {
      bg: 'heading_label',
      padding: '0px 10px',
      position: 'relative',
      left: '-10px',
  },
  
  
  '.transition.horizontal': {
    position: 'absolute',
    display: ['none', 'block'],
    bottom: ['50px'],
    fontSize: [, '20px'],
    fontWeight: [, 400],
    width: '250px',
    padding: '5px',
    
  }
})}
`
 
  
const Fallback = () =>  <div>FALLBACK</div>
export default withTheme(({cats, title, theme, mode}) => {


    

    const domContent = useRef()

    return <>
        <Canvas
            invalidateFrameloop
            camera={{ fov: 110, position: [0, 0, 1500], near: 0.1, far: 20000  }}
            onCreated={({ camera }) => camera.lookAt(0, 0, 0)}>
                <ambientLight intensity={0.5} />
                <spotLight intensity={0.5} position={[300, 300, 4000]} />
                <Suspense fallback={null}>
                    <SceneWrapper mode={mode} />
                    <HtmlContent portal={domContent}>
                        <Categories {...{cats, title, theme}} />
                        <Copywrite {...{position: 'absolute', bottom: 0, width: '100%'}} />
                    </HtmlContent>
                </Suspense>
        </Canvas>
        <div ref={domContent} />    
    </>
})